import reducePreloads from '@swe/shared/network/helpers/reduce-preloads';
import { isAbsoluteURL } from '@swe/shared/providers/router/utils';
import { ChevronRightIcon } from '@swe/shared/ui-kit/components/icon';
import Loader from '@swe/shared/ui-kit/components/loader';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';

import Stack from '@swe/shared/ui-kit/components/stack';

import { useEffect } from 'react';

import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import PageHeader from 'common/components/page-header';

import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';
import { usePoliciesList } from 'common/use-cases/policies';
import { useGoPrevious } from 'common/use-cases/use-go-previous';
import GetShopConfigEndpoint from 'endpoints/shop/get-shop-config';

const TITLE = 'Agreements';

const useSections = () => {
  const { policies } = usePoliciesList();
  const navigate = useRouterNavigate();

  return policies.map(({ title, href }) => ({
    children: title,
    trailIcon: ChevronRightIcon,
    size: 'lg' as const,
    onClick: () => (typeof href === 'string' && isAbsoluteURL(href) ? window.open(href) : navigate(href)),
  }));
};

const AgreementsIndexPage: ApplicationPage = () => {
  const goPrevious = useGoPrevious({ defaultRoute: Routes.Home });
  const sections = useSections();

  useEffect(() => {
    if (sections.length === 0) {
      goPrevious();
    }
  }, [goPrevious, sections]);

  if (sections.length === 0) {
    return (
      <Loader
        centered
        size="lg"
      />
    );
  }

  return (
    <Page>
      <PageHeader
        title={TITLE}
        defaultBackRoute={Routes.Home}
        enableMarginBottom
      />
      <Stack
        divider
        spacing="none"
      >
        {sections.map((p, idx) => (
          <SectionHeading
            key={idx}
            {...p}
          />
        ))}
      </Stack>
    </Page>
  );
};

AgreementsIndexPage.getMeta = () => ({ title: TITLE });

AgreementsIndexPage.preload = async ({ headers, queryClient }) => {
  return reducePreloads([GetShopConfigEndpoint.preload({}, { headers }, queryClient)]);
};

AgreementsIndexPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default AgreementsIndexPage;
